// 上传文件及打包下载
import axios from "./Interceptor.js"
import qs from "qs";
let mUrl =window.global_config.BASE_URL

// 新增档案管理
export const addManagement = (info) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicArchivesManagement/Insert',
        data: info
    })
}

// 修改档案管理
export const editManagement = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicArchivesManagement/Update',
        data,
    })
}

// 删除档案管理
export const delManagement = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicArchivesManagement/Delete',
        data,
    })
}

// 返回档案管理对像
export const infoManagement = (data) => {
    return axios({
        method: "get",
        url: mUrl + 'TopicArchivesManagement/Get',
        params: data,
    })
}

//返回管理对像列表分页
export const GetPagedManagement = (data) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicArchivesManagement/GetPaged',
        data,
    })
}

// 档案管理需要上传的字段
export const TopicArchivesManagementData = {
    Id: 0,
    Topicid: "",
    DataGuid: "",
    ArchivesCode: "", //证书号,检索号
    ArchivesName: "", //档案名称，论文名称，软件名称，文件名称
    IssuingDate: "", //发表日期，申请日期
    JournalArticle: "", // 期刊名称
    ArchivesUser: "",
    Scope: "",
    Category: "",
    Publisher: "",
    Source: "",
    ArchivesNumber: "",
    AuthorizationDate: "",
    AuthorizationNumber: "",
    PatentUser: "",
    Address: "",
    DevelopmentDate: "",
    FirstDate: "",
    GetDate: "",
    Method: "",
    CreateDate: "",
    Type: "", // 类型1，2，3，4.5
    UserId: 0,
    Remarks: "",
    Searching: "",
    Factor: "",
    LabelFlag: 1,

}

//导出管理列表
export const exportTableManagement = (info) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicArchivesManagement/ExportTable',
        // data: qs.stringify(info),
        data: info,
        responseType:'blob',
    })
}

//导出凭证列表
export const exportTableFinanc = (info) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicFinanc/ExportTable',
        // data: qs.stringify(info),
        data: info,
        responseType:'blob',
    })
}

//导出通用文件列表 相关文件，任务档案，，总结，其它
export const exportTableType = (info) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicInfo/ExportTableDataType',
        // data: qs.stringify(info),
        data: info,
        responseType:'blob',
    })
}

//导出凭证列表资金的表格
export const exportTableSelectFinancAccount = (info) => {
    return axios({
        method: "post",
        url: mUrl + 'TopicFinanc/ExportTableSelectFinancAccount',
        // data: qs.stringify(info),
        data: info,
        responseType:'blob',
    })
}

